import React from 'react'
import styles from './style.module.scss'
import Img from 'gatsby-image'
import { useStaticQuery, graphql, Link } from 'gatsby';

export default () => {

    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `)

    return (
        <div className={styles.header}>
            <div className="op_block">
                <Link to="/">
                    <Img className={styles.header__logo} fluid={data.file.childImageSharp.fluid} />
                </Link>
            </div>
        </div>
    )
}