import React from 'react'
import styles from './style.module.scss'
import Img from 'gatsby-image'
import { useStaticQuery, graphql, Link } from 'gatsby'

export default () => {

    const data = useStaticQuery(graphql`
        query {
            
            allFile {
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                            fluid(maxWidth: 1440) {
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                            }
                        }
                    }
                }
            }
        }
    `)

    const imagePath = (fileName) => {
        const image = data.allFile.edges.find(n => {
            return n.node.relativePath.includes(fileName)
        })

        return image.node.childImageSharp.fluid
    }

    return (
        <div>
            <div className="op_block space_block">
                <div className={styles.footer}>
                    <div className={styles.footer_divider}></div>
                    <div className={styles.footer_content}>
                        <div className={styles.footer_content_logo}>
                            <Link to="/">
                                <Img fluid={imagePath("logo.png")} className={styles.footer_content_logo_img} />
                            </Link>
                        </div>
                        <div className={styles.footer_content_about}>
                            <div className={styles.footer_content_about_title}>
                                About Us
                            </div>
                            <ul>
                                <li>16 Raffles Quay, Hong Leong Building, Singapore 048581</li>
                                <li>+65-670-49011</li>
                                <li>info@epicrondigital.com</li>
                            </ul>
                        </div>
                        <div className={styles.footer_content_legal}>
                            <div className={styles.footer_content_legal_title}>
                                Legal Stuff
                            </div>
                            <ul>
                                <li>
                                    <Link to="/disclaimer">Disclaimer</Link>
                                </li>
                                <li>
                                    <Link to="/privacy">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/terms">Terms of Service</Link>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.footer_content_publish}>
                            <div className={styles.footer_content_publish_title}>
                                Published
                            </div>
                            <ul>
                                <li>
                                    <a href="https://play.google.com/store/apps/dev?id=4635102246278193668" target="_blank">PlayStore</a>
                                </li>
                                <li>AppStore</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Img fluid={imagePath("footer_image.png")} />
        </div>
    )
}