import React from 'react'
import '@/styles/styles.scss'

import Header from '../Header'
import Footer from '../Footer'
import SEO from '../Seo/seo'

export default ({children, title}) => {

    return (
        <div>
            <SEO title={title}/>
            <Header />
                {children}
            <Footer />
        </div>
    )
}